@charset "UTF-8";
// Core variables and mixins
@import "res/scss/__variables.scss";
@import "res/scss/__mixins.scss";

/*=====PC=====*/
.nav-open {
  overflow-x:hidden;
}
.pc-header {
  position: fixed;
  top: 0;
  left:0;
  width:100%;
  z-index: 700; 
  .pc-nav-warp{
    min-width: 1024px;
    height:75px;
    position: absolute;
    top:50px;
    left:0;
    width:100%;
    height: 0;
    padding-right: 20px;
    padding-left: 20px;
    @include media-query-min(xl) {
      // min-width: 1280px;
      max-width: 1575px;
      left:50%;
      transform: translateX(-50%); 
      -webkit-transform: translateX(-50%);
    }
  }
  &.on .nav-fullScreen-wrap{
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.pc-header .logo {
  position:absolute;
  top:30px;
}
.pc-header.pc-scroll .logo {
  top:0;
}
.pc-header .logo .logo-img {
  display: inline-block;
  vertical-align: middle;
  width: 119px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(/views/res/imgs/common/main_logo.png);
}
.bg-black.pc-nav-warp .logo .logo-img {
  background-image: url(/views/res/imgs/common/main_logo_01.png);
}

/* 네비게이션 컨텐츠 */
.pc-scroll .pc-nav {
  display:block;
}
.pc-nav{
  display:none;
  position:absolute;
  top: 19px;
  right:80px;
  @include media-query-min(xl) {
    right:170px;
  }
}
.pc-nav .menu-list{
  position:relative;
  display:inline-block;
  padding:0 7px;
}

.pc-nav .menu-list > a{
  display:inline-block;
  padding:20px 15px;
  vertical-align: bottom;
  font-size:13px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 0.04em;
  transition: color .5s;
  -webkit-transition: color .5s;
  text-transform: uppercase;
  &:after{
    content:"";
    position: absolute;
    bottom:0;
    left:0;
    width:0;
    height:3px;
    background-color:#00aeff;
    opacity: 0;
    transition:.3s;
    -webkit-transition:.3s;
  }
}

.pc-nav .menu-list:hover{
  a{
    color:$g_point-font-color-sky;
    &:after{
      width:100%;
      opacity: 1;
      transition:.3s;
      -webkit-transition:.3s;
    }
  }
}

.pc-nav .menu-list.on{
  a{
    color:$g_point-font-color-sky;
    &:after{
      width:100%;
      opacity: 1;
    }
  }
}

.pc-header .nav-menu{
  position:absolute;
  right: 0px;
  top: 80px;
  height:100px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  z-index:900;
}
.pc-header.on .pc-nav-warp .nav-menu{
  z-index:1;
}
.pc-header .nav-menu > *{
  display: inline-block;
  vertical-align: middle;
}

.pc-header .nav-menu .menu-btn{
  z-index:99999;
  position: absolute;
  right:20px;
  width: 30px;
  cursor: pointer;
}
.bg-black.pc-nav-warp .nav-menu.open .menu-btn span{
  background-color: #111;
}


/* 네비게이션 메뉴 버튼 */
.menu-btn span{
  display: block;
  width: 30px;
  height: 2px;
  margin-bottom: 7px;
  background-color: #fff;
  -webkit-transition: .5s;
  transition: .5s;
}


.menu-btn.on span{
  background-color: #fff;
}

.menu-btn span:last-child{
  margin-bottom: 0;
}

.menu-btn.on .one{
  -webkit-transform: translateY(9px) rotateZ(135deg);
  transform: translateY(9px) rotateZ(135deg);
}
.menu-btn.on .two{
  opacity: 0;
}
.menu-btn.on .three{
  -webkit-transform: translateY(-9px) rotateZ(-135deg);
  transform: translateY(-9px) rotateZ(-135deg);
}

.menu-btn.bg-black span{
  background-color: #111;
}

/* 네이게이션 컨텐츠 */
.nav-fullScreen-wrap {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 600;
  overflow: hidden;
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
  opacity:0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  text-align: center;

  &:before {
    content:'';
    display:block;
    position:absolute;
    width:100%;
    height:100%;

    background: #461da3;
    background: -moz-linear-gradient(-45deg, #203864 0%, #461da3 100%);
    background: -webkit-linear-gradient(-45deg, #203864 0%, #461da3 100%);
    background: linear-gradient(135deg, #203864 0%, #461da3 100%);
    -webkit-transform: translateX(210%) skew(45deg);
    transform: translateX(210%) skew(45deg);
    transition: all .8s cubic-bezier(.55,.055,.675,.19);

    z-index: 1;

  }
  &.tempHide:before {
    opacity:1;
    -webkit-transform: translateX(200%) skew(-45deg)!important;
    transform: translateX(200%) skew(-45deg)!important;
    transition: all 0.8s cubic-bezier(.55,.055,.675,.19);
  }
  &.show:before {
    opacity:1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }

  .nav-menu.close {
    position: absolute;
    top: 80px;
    left: 50%;
    width: 100%;
    height:50px;
    padding:0 20px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    @media screen and (min-width: 1280px) {
      min-width: 1280px;
      max-width: 1575px;
    }
  }

  $menuTime : 0.5;
  .menu-list {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    transition:transform .3s;
    &:nth-child(1) {
      -webkit-transition-delay: $menuTime+s;
      transition-delay: $menuTime+s;
    }
    &:nth-child(2) {
      -webkit-transition-delay: $menuTime+0.1+s;
      transition-delay: $menuTime+0.1+s;
    }
    &:nth-child(3) {
      -webkit-transition-delay: $menuTime+0.15+s;
      transition-delay: $menuTime+0.15+s;
    }
    &:nth-child(4) {
      -webkit-transition-delay: $menuTime+0.2+s;
      transition-delay: $menuTime+0.2+s;
    }
    &:nth-child(5) {
      -webkit-transition-delay: $menuTime+0.25+s;
      transition-delay: $menuTime+0.25+s;
    }
    &:nth-child(6) {
      -webkit-transition-delay: $menuTime+0.3+s;
      transition-delay: $menuTime+0.3+s;
    }
  }
  
  &.show .menu-list {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  $menuTimeOut : 0.1;
  &.tempHide .menu-list{
    -webkit-transform: translateX(100%) !important;
    transform: translateX(100%) !important;
    &:nth-child(1) {
      -webkit-transition-delay: $menuTimeOut+s;
      transition-delay: $menuTimeOut+s;
    }
    &:nth-child(2) {
      -webkit-transition-delay: $menuTimeOut+0.1+s;
      transition-delay: $menuTimeOut+0.1+s;
    }
    &:nth-child(3) {
      -webkit-transition-delay: $menuTimeOut+0.15+s;
      transition-delay: $menuTimeOut+0.15+s;
    }
    &:nth-child(4) {
      -webkit-transition-delay: $menuTimeOut+0.2+s;
      transition-delay: $menuTimeOut+0.2+s;
    }
    &:nth-child(5) {
      -webkit-transition-delay: $menuTimeOut+0.25+s;
      transition-delay: $menuTimeOut+0.25+s;
    }
    &:nth-child(6) {
      -webkit-transition-delay: $menuTimeOut+0.3+s;
      transition-delay: $menuTimeOut+0.3+s;
    }
  }

  .nav-fullScreen {
    position: absolute;
    width: 100%;
    top: 50%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
    .aco a {
      height: 8vh;
      font-size: 16px;
      font-weight:700;
      color: #fff;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      -webkit-transition: .3s;
      transition: .3s;
    }
  }
  .nav-fullScreen > .menu-list > a:hover{
    color:$g_point-font-color-sky;
  }

  .sns-icon-wrap {
    position: absolute;
    left:0;
    bottom:0;
    width:100%;
    height:70px;
    margin-top:0;
    margin-bottom:50px;
    text-align: center;
    z-index:3;
    -webkit-transform: translate(100%,0);
    transform: translate(100%,0);
    transition: transform .3s;
    li {
      display:inline-block;
      float:none;
    }
  }
  .copyright {
    position: absolute;
    bottom: 0;
    width: 100%;
    height:70px;
    font-size:9px;
        font-family: "Roboto", sans-serif;
    letter-spacing: 0.05em;
    color:#fff;
    text-align: center;
    z-index:2;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transition: transform .3s;
  }
  &.show{
    .sns-icon-wrap{
      -webkit-transition-delay: $menuTime+0.35+s;
      transition-delay: $menuTime+0.35+s;
      transform: translate(0,0);
    }
    .copyright {
      -webkit-transition-delay: $menuTime+0.4+s;
      transition-delay: $menuTime+0.4+s;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  &.tempHide {
    .sns-icon-wrap{
      -webkit-transform: translate(100%,0) !important;
      transform: translate(100%,0) !important;
      -webkit-transition-delay: $menuTimeOut+0.3+s;
      transition-delay: $menuTimeOut+0.3+s;
    }
    .copyright {
      -webkit-transform: translateX(100%) !important;
      transform: translateX(100%) !important;
      -webkit-transition-delay: $menuTimeOut+0.3+s;
      transition-delay: $menuTimeOut+0.3+s;
    }
  }
}
