// Use in conjunction with .sr-only to only display content when it's focused.
// See: http://a11yproject.com/posts/how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/
@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}
@mixin text-hide {
  font: 0/0 a !important;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

