@mixin make-form-wrap( $form-wrap-info : $g_form-wrap ) {
  @each $key, $val in ($form-wrap-info){
    @if ($key == default_value) {
      %default_value {
        @each $key2, $val2 in $val {
          #{$key2}: #{$val2};
        }
      }
    } @else {
      .form-wrap-#{$key} {
        @if (map-has-key($form-wrap-info, default_value)) {
          @extend %default_value;
        }
        @if (map-has-key($val, media)) {
          $media: map_get($val, media);
          @each $key2, $val2 in $media {
            @include media-query-min($key2) {
              $codes: makeMapToCodes($val2);
              @include printCodes($codes);
            }
          }
        } @else {
          @each $key3, $val3 in $val {
            $codes: makeMapToCodes($key3);
            @include printCodes($codes);
          }
        }
      }
    }
  }
}

// /*텍스트 박스*/
@mixin make-field-default() {
  %field-base {
    border: none;
    outline: none;
  }
  @each $key, $val in ($g_field-style) {
    .field-#{$key} input[type="text"],
    .field-#{$key} input[type="password"],
    .field-#{$key} input[type="tel"],
    .field-#{$key} input[type="email"],
    .field-#{$key} select,
    .field-#{$key} textarea{
      @extend %field-base;
      width: 100%;
      max-width: map-get($val, max-width);
      height: map-get($val, input-height);
      vertical-align: middle;
      margin-bottom: map-get($val, margin-bottom);
      padding: map-get($val, input-padding);
      background-color: map-get($val, input-bg);
      @each $key2, $val2 in map-get($val, border-style) {
        #{$key2}: #{$val2};
      }
      font-size: map-get($val, font-size);
      letter-spacing: map-get($val, letter-spacing);
      color: map-get($val, font-color);
      @if map-has-key($val, focus-style) {
        $focus-style: map-get($val, focus-style);
        @each $key3, $val3 in $focus-style {
          &:focus {
            #{$key3} : #{$val3};
          }
        }
      }
    }
    .field-#{$key} input[type="text"],
    .field-#{$key} input[type="button"],
    .field-#{$key} input[type="password"],
    .field-#{$key} input[type="email"],
    .field-#{$key} textarea{
      &::placeholder {
        color: map-get($val, placeholder-color);
      }
      &:-ms-input-placeholder {
        color: map-get($val, placeholder-color);
      }
      &::-ms-input-placeholder {
        color: map-get($val, placeholder-color);
      }
    }

    .field-#{$key}.no-underline {
      border-bottom: transparent;
    }
    @include make-text-area($key);
    @include make-selectbox($key);

  }
}
@mixin make-text-area($style) {
  @each $key, $val in ($g_text-area-style) {
    @if ($style == $key) {
      .field-#{$style} textarea{
        height: map-get($val, text-area-height);
        padding: map-get($val, text-area-padding);
        resize: none;
      }
    }
  }
}

// /*셀렉트 박스*/
@mixin make-selectbox($style) {
  @each $key, $val in ($g_select-style) {
    @if ($style == $key) {
      .field-#{$style} select{
        $codes: makeMapToCodes($val);
        @include printCodes($codes);
        background-repeat:no-repeat;
        background-size: initial;
        background-color: transparent;
      }
    }
  }
}

// /*이미지 체크 & 라디오*/
@mixin make-checkbox-img( $check-box-info: $g_check-box-img ) {
  @each $key, $val in ($check-box-info) {
    .ckbx-#{$key}{
      display: inline-block;
      margin-right: map-get($val, margin-right);
      cursor: pointer;
      .custom-checked {
        content: "";
        display: inline-block;
        width: map-get($val, width);
        height: map-get($val, height);
        margin-right: map-get($val, box-margin-right);
        background-image: map-get($val, box-img);
        background-repeat: no-repeat;
        background-size: cover;
        vertical-align: middle;

      }
      .text {
        display: inline-block;
        font-size: map-get($val, font-size);
      }
      input {
        display: none;
        &[type=radio]:checked ~ .custom-checked,
        &[type=checkbox]:checked ~ .custom-checked {
          background-image: map-get($val, box-img-on);
        }
      }
      &:last-child {
        margin-right: 0;
      }
      &.disabled {
        pointer-events: none;
        color: #eee;
      }
    }
  }
}
// /*태그 체크& 라디오*/
@mixin make-checkbox-tag( $check-box-info: $g_check-box-tag ) {
  @each $key, $val in ($check-box-info) {
    //변수
    $width: map-get($val, width);
    $height: map-get($val, height);
    $box-margin-right: 0;
    $check-width: $width / 2;
    $check-height: $height / 2;

    //해당값이 있는지 체크
    @if map-has-key($val, box-margin-right) {
      $box-margin-right: map-get($val, box-margin-right)
    }
    @if map-has-key($val, checked-width) {
      $check-width: map-get($val, checked-width)
    }
    @if map-has-key($val, checked-height) {
      $check-height: map-get($val, checked-height)
    }
    .ckbx-#{$key}{
      display: inline-block;
      margin-right: map-get($val, margin-right);
      position: relative;
      min-width: $width;
      min-height: $height;
      cursor: pointer;
      &:before {
        position: absolute;
        top: 50%;
        left: 0;
        display: inline-block;
        content: "";
        width: $width;
        height: $height;
        background-color: map-get($val, box-bg);
        border: map-get($val, box-outline);
        border-radius: map-get($val, box-radius);
        @include prefixer(transform, translateY(-50%), webkit);
      }
      > * {
        // vertical-align: text-top; 게시판 글쓰기 비밀글에서 깨져서 우선 주석처리
      }
      .text {
        display: inline-block;
        font-size: map-get($val, font-size) ;
        padding-left: $box-margin-right + $width;
      }
      input {
        display: none;
        &[type=radio]:checked ~ .custom-checked,
        &[type=checkbox]:checked ~ .custom-checked {
          position: absolute;
          top: 50%;
          left: ($width - $check-width) / 2 ;
          display: inline-block;
          content: "";
          width: $check-width;
          height: $check-height;
          background-color: map-get($val, checked-color);
          border-radius: map-get($val, box-radius);
          @include prefixer(transform, translateY(-50%), webkit);
        }
      }
      &:last-child {
        margin-right: 0;
      }
      &.disabled{
        pointer-events: none;
        color: #eee;
        label:before{
          border-color: #eee;
        }
      }
      &.circle:before {
        border-radius: 50%;
      }
      &.circle input {
        &[type=radio]:checked ~ .custom-checked,
        &[type=checkbox]:checked ~ .custom-checked {
          border-radius: 50%;
        }
      }
    }
  }
}