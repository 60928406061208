//rp-table
@mixin make-rp-table($rp-table-info) {
  @each $key, $val in ($rp-table-info){
    @if ($key == default_value) {
      %default_value {
        @each $key2, $val2 in $val {
          #{$key2}: #{$val2};
        }
      }
    } @else {
      .rp-table-#{$key} {
        @if (map-has-key($val, media)) {
          $media: map_get($val, media);
          @each $key2, $val2 in $media {
            @include media-query-min($key2) {
              $codes: makeMapToCodes($val2);
              @include printCodes($codes);
            }
          }
        } @else {
          @each $key3, $val3 in $val {
            $codes: makeMapToCodes($key3);
            @include printCodes($codes);
          }
        }
      }
    }
  }
}








