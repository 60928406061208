@mixin make-card-set($card-info) {
  %card-wrap{
    position: relative;
    display: block;
  }
  @each $key, $val in $card-info {
    @each $key2, $val2 in $val {
      @if $key2 == "wrap" {
        .card-#{$key2}-#{$key} {
          @extend %card-wrap;
          margin-bottom: map-get($val2, margin-bottom);
          padding: map-get($val2, padding);
          background-color: map-get($val2, background-color);
          @each $key3, $val3 in  map-get($val2, border-style) {
            #{$key3}: #{$val3};
          }
          border-radius: map-get($val2, border-radius);
        }
      }
      @else if $key2 == "header" {
        .card-wrap-#{$key}{
          .card-#{$key2} {
            padding: map-get($val2, padding);
            background-color: map-get($val2, background-color);
            @each $key3, $val3 in  map-get($val2, border-style) {
              #{$key3}: #{$val3};
            }
            font-size: map-get($val2, font-size);
          }
        }
      } @else {
        .card-wrap-#{$key}{
          .card-#{$key2} {
            padding: map-get($val2, padding);
            background-color: map-get($val2, background-color);
            @each $key3, $val3 in  map-get($val2, border-style) {
              #{$key3}: #{$val3};
            }
          }
        }
      }
    }
  }
}
// 다 나누고 하나로 합치는게 좋지 않을까???????
// 상속의 계념을 적용해야 하는가??
// 상속을 적용하면 코드가 조금 복잡해질듯
// 아예 wrap, header, body, footer 나누는던지, 지금처럼 처리하는게 나을듯
