@mixin triangle($direction,$width,$height,$widthSize,$heightSize,$color) {
    content: "";
    position:absolute;
    height: $width;
    width: $height;
    border-style: solid;
    @if $width == 0 {
        @if $width == 0 and $height == 0 {
            border-color: transparent transparent $color;
            border-width: 0 ($widthSize / 2) $heightSize;
        } @else if $direction == "up-right" {
            border-color: transparent $color transparent transparent;
            border-width: 0 $widthSize $widthSize 0;
        } @else if $direction == "right" {
            border-color: transparent transparent transparent $color;
            border-width: ($heightSize / 2) 0 ($heightSize / 2) $widthSize;
        } @else if $direction == "down-right" {
            border-color: transparent transparent $color;
            border-width: 0 0 $widthSize $widthSize;
        } @else if $direction == "down" {
            border-color: $color transparent transparent;
            border-width: $heightSize ($widthSize / 2) 0;
        } @else if $direction == "down-left" {
            border-color: transparent transparent transparent $color;
            border-width: $widthSize 0 0 $widthSize;
        } @else if $direction == "left" {
            border-color: transparent $color transparent transparent;
            border-width: ($heightSize / 2) $widthSize ($heightSize / 2) 0;
        } @else if $direction == "up-left" {
            border-color: $color transparent transparent;
            border-width: $widthSize $widthSize 0 0;
        }
    }@else {
        border-color: transparent $color $color transparent;
        border-width: 0 $widthSize $heightSize 0;
        @if $direction == "up" {
            transform:rotate(-135deg);
        } @else if $direction == "up-right" {
            transform:rotate(-90deg);
        } @else if $direction == "right" {
            transform:rotate(-45deg);
        } @else if $direction == "down-right" {
            transform:rotate(0deg);
        } @else if $direction == "down" {
            transform:rotate(45deg);
        } @else if $direction == "down-left" {
            transform:rotate(90deg);
        } @else if $direction == "left" {
            transform:rotate(135deg);
        } @else if $direction == "up-left" {
            transform:rotate(180deg);
        }
    }
}


// .element {
//     position:relative;
//     &::before {
//         @include triangle("up-left", 0,0,10px,10px, #b25c9c);
//     }
// }
// .element2 {
//     left:20px;
//     position:relative;
//     &::before {
//         @include triangle("up-left", 8px,8px,2px,2px, #b25c9c);
//     }
// }

// css
// .element2 {
//     content: "";
//     position: absolute;
//     border-style: solid;
//     height: 8px;
//     width: 8px;
//     border-color: transparent #b25c9c #b25c9c transparent;
//     border-width: 0 2px 2px 0;
//     transform: rotate(180deg);
// }
