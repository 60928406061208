// /* prefix */
@mixin prefixer($property, $value, $prefixes:()){
  @each $prefix in $prefixes{
    #{'-'+$prefix+'-'+$property}:$value;
  }
  #{$property} : $value;
}

  // @include prefixer(transform, rotate(45deg), webkit ms); or ("webkit", "moz")

 //css
  // .selector {
  //   -webkit-transform: rotate(45deg);
  //   -ms-transform: rotate(45deg);
  //   transform: rotate(45deg);
  // }

