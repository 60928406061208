// make-row 믹스인
// [매개변수]
// $grid-gutter 기본값: $grid-gutter-width
// $grid-gutter 전달된 값에 따라서 margin-right, margin-left 값을 셋팅해 줍니다.
// ex) @include make-row( $grid-gutter: $grid-gutter-width);
@mixin make-row( $grid-gutter : $grid-gutter-width) {
  display: block;
  margin-right: -$grid-gutter;
  margin-left: -$grid-gutter;
  @include clear();
}

// make-col-set 믹스인
// [매개변수]
// $grid-col-cnt 기본값: $grid-columns
// $grid-col-cnt 전달된 값의 개수만킁의 col,pull,push,offset 등을 만들어줍니다.
// ex) @include make-col-set();
// ex) @include make-col-set($grid-col-cnt: 5);
@mixin make-col-set( $grid-col-info: $g_grid-col-info ) {
  $grid-col-name: null;
  @if  map-has-key($grid-col-info, grid-col-name) {
    $grid-col-name : map-get($grid-col-info, grid-col-name)
  } @else {
    $grid-col-name : null
  }
    @include make-col( map-get($grid-col-info, grid-col-cnt), $grid-col-name );
  @include make-pull( map-get($grid-col-info, grid-col-cnt), $grid-col-name );
  @include make-push( map-get($grid-col-info, grid-col-cnt), $grid-col-name );
  @include make-offset( map-get($grid-col-info, grid-col-cnt), $grid-col-name );
}


@mixin make-col( $grid-col-cnt: $grid-col-cnt, $col-name: $col-name ) {
  @each $breakpoint, $point in $g_media-breakpoints {
    @if $breakpoint == xs {
      .#{$col-name}col {
        max-width: 100%;
      }
      .#{$col-name}col-auto {
        width: auto;
      };
      @for $i from 1 through $grid-col-cnt {
        .#{$col-name}col-#{$i} {
          width: percentage($i / $grid-col-cnt);
        }
      }
    } @else {
      @media (min-width: #{$point}) {
        .#{$col-name}col-#{$breakpoint} {
          max-width: 100%;
        }
        .#{$col-name}col-#{$breakpoint}-auto {
          width: auto;
        };
        @for $i from 1 through $grid-col-cnt {
          .#{$col-name}col-#{$breakpoint}-#{$i} {
            width: percentage($i / $grid-col-cnt);
          }
        }
      }
    }
  }
}

@mixin make-pull( $grid-col-cnt: $grid-col-cnt, $col-name: $col-name ) {
  @each $breakpoint, $point in $g_media-breakpoints {
    @if $breakpoint == xs {
      .#{$col-name}pull-0 {
        right: auto;
      }
      @for $i from 1 through $grid-col-cnt {
        .#{$col-name}pull-#{$i} {
          right: percentage($i / $grid-col-cnt);
        }
      }
    } @else {
      @media (min-width: #{$point}) {
        .#{$col-name}pull-#{$breakpoint}-0 {
          right: auto;
        }
        @for $i from 1 through $grid-col-cnt {
          .#{$col-name}pull-#{$breakpoint}-#{$i} {
            right: percentage($i / $grid-col-cnt);
          }
        }
      }
    }
  }
}

@mixin make-push( $grid-col-cnt: $grid-col-cnt, $col-name: $col-name ) {
  @each $breakpoint, $point in $g_media-breakpoints {
    @if $breakpoint == xs {
      .#{$col-name}push-0 {
        left: auto;
      }
      @for $i from 1 through $grid-col-cnt {
        .#{$col-name}push-#{$i} {
          left: percentage($i / $grid-col-cnt);
        }
      }
    } @else {
      @media (min-width: #{$point}) {
        .#{$col-name}push-#{$breakpoint}-0 {
          left: auto;
        }
        @for $i from 1 through $grid-col-cnt {
          .#{$col-name}push-#{$breakpoint}-#{$i} {
            left: percentage($i / $grid-col-cnt);
          }
        }
      }
    }
  }
}

@mixin make-offset( $grid-col-cnt: $grid-columns, $col-name: $col-name ) {
  @each $breakpoint, $point in $g_media-breakpoints {
    $point: media-query-min-val($breakpoint);
    @if $breakpoint == xs {
      @for $i from 1 through $grid-col-cnt - 1 {
        .#{$col-name}offset-#{$i} {
          margin-left: percentage($i / $grid-col-cnt);
        }
      }
    } @else {
      @media (min-width: #{$point}) {
        @for $i from 1 through $grid-col-cnt - 1 {
          .#{$col-name}offset-#{$breakpoint}-#{$i} {
            margin-left: percentage($i / $grid-col-cnt);
          }
        }
      }
    }
  }
}

