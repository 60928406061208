///* tab */
@mixin tab-items($tab-group) {
    .tab-wrap {
        text-align:center;
        & .tab-list {
            //float:map-get($tab-group, float);
            display:map-get($tab-group, display);
            width: map-get($tab-group, width);
            padding: map-get($tab-group, padding);
            background-color:map-get($tab-group, theme-color);
            font-size:map-get($tab-group, font-size);
            font-weight:map-get($tab-group, font-weight);
            color:map-get($tab-group, font-color);
            text-align:map-get($tab-group, text-align);
            letter-spacing:map-get($tab-group, letter-spacing);

            @if map-has-key($tab-group, media) {
                @each $key2, $val2 in map_get($tab-group, media) {
                    @include media-query-min($key2) {
                        @each $key2, $val2 in $val2{
                            #{$key2}: #{$val2};
                        }
                    }
                }
            }
        }
        @each $key, $value in (map-get($tab-group, sub-classing)){
            &.#{$key} .tab-list{
                @each $key2, $val2 in $value{
                    #{$key2}: #{$val2};
                }
            }
        }

        & .tab-list.on {
            @each $key2, $val2 in (map-get($tab-group,active)){
                #{$key2}: #{$val2};
            }
        }
        @if map-has-key($g_tab-group,float){
            @include clear();
        }
    }

}