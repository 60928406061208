/////default/////
//color
$g_white: #fff !default;
$g_black: #000 !default;
$g_red: #ff0000;

$g_point-font-color: #461da3;
$g_point-font-color-sky: #00aeff;
$g_point-theme-color: #461da3;


//font
//-font-family
$g_font-family: (
  system: ' "맑은 고딕", "Malgun Gothic", "애플 SD 산돌고딕 Neo", "Apple SD Gothic Neo", sans-serif ',
  opensans : '"Open Sans", sans-serif',
  nanumsqure: '"NANUMSQUARE", sans-serif',
  dotum: '"Dotum", sans-serif',
  roboto: '"Roboto", sans-serif'
);
$font-system: #{map-get($g_font-family, system)};
$font-opensans: #{map-get($g_font-family, opensans)};
$font-nanumsqure: #{map-get($g_font-family, nanumsqure)};
$font-dotum: #{map-get($g_font-family, dotum)};
$font-roboto: #{map-get($g_font-family, roboto)};

//-font-size
$g_font-size:(
  xs: 10px,
  sm: 12px,
  md: 15px,
  lg: 18px,
  xl: 24px,
);

//-font-weight
$g_font-weights: 100, 200, 300, 400, 500, 600, 700, 800, 900;

//-letter-spacing

/////media/////
$g_media-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

/////layout/////
//grid
$g_grid-columns: 12;
$g_grid-gutter-width: 1%;

//grid
$g_grid-col-info: (
  grid-col-cnt: 12,
  grid-col-name: null
);
$grid-col5-info: (
  grid-col-cnt: 5,
  grid-col-name: five-
);

/////UTILITIES/////
//clear

//spacing
$spacers:(
  0: 0,
  5: 5px,
  10: 10px,
  20: 20px,
  25: 25px,
  30: 30px,
  40: 40px,
  50: 50px,
  60: 60px,
  70: 70px,
  80: 80px,
  90: 90px,
  100: 100px
);

//===================================================================================================================//


